import React from 'react';
import Animate from '../../../common/components/Animate';

const DashboardHome = () => {
  return (
    <Animate>
      <div>DashboardHome</div>
    </Animate>
  );
};

export default DashboardHome;
