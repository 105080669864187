/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
/* eslint-disable react/react-in-jsx-scope */
import { Breadcrumb, Container, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import '../../../../styles/breadcrumb.scss';

const DynamicBreadcrumb = () => {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);

  function capitalizeFirstLetter(input: string) {
    const textMod = input.replace('-', ' ');
    return textMod
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }
  return (
    <div className="d-flex justify-content-center w-50 m-auto">
      <Container fluid>
        <Row>
          <Breadcrumb className="custom-breadcrumb">
            <Breadcrumb.Item href="/">Baza Historii Kobiet</Breadcrumb.Item>
            {pathnames.map((part: string, index: number) => {
              const isLast = index === pathnames.length - 1;
              const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
              const partMod = capitalizeFirstLetter(part);
              return isLast ? (
                <Breadcrumb.Item active key={index}>
                  {partMod}
                </Breadcrumb.Item>
              ) : (
                <Breadcrumb.Item href={routeTo} key={index}>
                  {partMod}
                </Breadcrumb.Item>
              );
            })}
          </Breadcrumb>
        </Row>
      </Container>
    </div>
  );
};

export default DynamicBreadcrumb;
