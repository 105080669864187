/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Row, Col, Card, Image, Container } from 'react-bootstrap';
import formattedYear from '../../../common/utils/formattedYear';
import '../../../../styles/womens.scss';
import FBIcon from '../../../../assets/img/icons/fb.png';
import InstaIcon from '../../../../assets/img/icons/instagram.png';
import TweetIcon from '../../../../assets/img/icons/tweet.png';
import HorizontalLine from '../../../../assets/img/icons/horizontalLine.svg';
import LogoItem from '../../../../assets/img/icons/logo.png';

const Footer = () => {
  const openLink = (urlLink: string) => {
    window.open(urlLink, '_blank');
  };
  return (
    <footer className="d-flex justify-content-center w-50 m-auto" style={{ fontSize: '12px' }}>
      <Container fluid>
        <Row>
          <Image alt="Muzeum historii kobiet" src={HorizontalLine} style={{ paddingBottom: '20px', paddingTop: '20px' }} />

          <Col xs={12} lg={6}>
            <Image alt="Mzeum historii kobiet" src={LogoItem} className="small-image-footer" />
          </Col>
          <Col xs={12} lg={3}>
            <p onClick={() => openLink('https://historiakobiet.org/idea')} style={{ cursor: 'pointer', fontWeight: 'bold' }}>
              Idea
            </p>
            <p onClick={() => openLink('https://historiakobiet.org/idea')} style={{ cursor: 'pointer', fontWeight: 'bold' }}>
              Projekty
            </p>
            <p
              onClick={() => openLink('https://historiakobiet.org/fundacja')}
              style={{ cursor: 'pointer', fontWeight: 'bold' }}
            >
              Fundacja
            </p>
            <p
              onClick={() => openLink('https://historiakobiet.org/matronaty')}
              style={{ cursor: 'pointer', fontWeight: 'bold' }}
            >
              Matronaty
            </p>
            <p
              onClick={() => openLink('https://historiakobiet.org/kontakt')}
              style={{ cursor: 'pointer', fontWeight: 'bold' }}
            >
              Kontakt
            </p>
          </Col>
          <Col xs={12} lg={3}>
            <div className="text-wrapper-38" style={{ fontWeight: 'bold' }}>
              Kontakt
            </div>
            <p className="p">Tel. +48 608 609 521</p>
            <a
              className="text-wrapper-29"
              href="mailto:kontakt@historiakobiet.org"
              rel="noopener noreferrer"
              target="_blank"
              style={{ marginTop: '5px' }}
            >
              kontakt@historiakobiet.org
            </a>
            <div className="footer-contact" style={{ marginTop: '10px' }}>
              <Row>
                <Col xs={4} className="mb-3">
                  <Image src={FBIcon} fluid style={{ width: '30px', height: '30px' }} />
                </Col>
                <Col xs={4} className="mb-3">
                  <Image src={InstaIcon} fluid style={{ width: '30px', height: '30px' }} />
                </Col>
                <Col xs={4} className="mb-3">
                  <Image src={TweetIcon} fluid style={{ width: '30px', height: '30px' }} />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
