/* eslint-disable react/no-this-in-sfc */
/* eslint-disable array-callback-return */
/* eslint-disable no-shadow */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-restricted-globals */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Button, Container, ListGroup, Image, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import ImageGallery from 'react-image-gallery';
import { Chrono } from 'react-chrono';
import Loader from '../common/components/Loader';
import formatNames from '../common/utils/formatName';
import sanitize from '../common/utils/sanitize';
import splitName from '../common/utils/splitName';
import checkOneOf from '../common/utils/checkOneOf';
import Animate from '../common/components/Animate';
import '../../styles/singleWomen.scss';
import { useGetSingleUserQuery } from '../users/users.api';
import { useGetSingleWomenQuery } from './womens.api';

// import stylesheet if you're not already using CSS @import
import 'react-image-gallery/styles/css/image-gallery.css';

const SingleWomen = () => {
  const { id } = useParams<string>();
  const [isLoaded, setLoaded] = useState<boolean>(false);

  const [value, setValue] = useState(0);
  const [previous, setPrevious] = useState(0);
  const [galleryItems, setGallery] = useState([]);

  const state = { value: 0, previous: 0 };
  let womenData: any = {};
  const result = id ? useGetSingleWomenQuery({ id }) : undefined; // Passing id as an object
  let womenKeysAreAvailable = false;
  if (result) {
    womenData = { ...result.data };

    womenKeysAreAvailable = Object.keys(womenData).length > 0;
  }
  useEffect(() => {
    if (womenKeysAreAvailable) {
      setLoaded(true);
      const { media } = womenData;
      const galleryArr: any = [];
      media?.photos?.map((photo: any) => {
        const galleryItem = {
          original: photo.url,
          thumbnail: photo.url,
          description: `${photo.name} ${photo.description}`,
        };
        galleryArr.push(galleryItem);
      });
      setGallery(galleryArr);
    }
  }, [womenKeysAreAvailable]);

  const formatDate = (dateString: Date) => {
    if (!dateString) {
      return '';
    }
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0'); // Add leading zero if needed
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Add leading zero if needed
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };

  return (
    <div className="d-flex justify-content-center w-50 m-auto">
      <Container fluid>
        <Loader show={!isLoaded} />
        {isLoaded && womenKeysAreAvailable && (
          <Animate>
            <Row className="single-women">
              <Col md={6}>
                <Image src={womenData.main_photo} alt={womenData.fullname} fluid />
              </Col>

              {/* Product Properties (Right) */}
              <Col md={6}>
                <Card.Body>
                  <Card.Title>{womenData.fullname}</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">{womenData.short_description}</Card.Subtitle>
                  <Card.Text>
                    <div>
                      <h6>Nazwisko rodowe</h6>
                      <p>{womenData.family_name}</p>
                    </div>
                    <div>
                      <h6>Czas życia</h6>
                      <p>
                        {womenData.death_time
                          ? womenData.death_time
                          : `${formatDate(womenData.born_date)} - ${formatDate(womenData.death_date)}`}
                      </p>
                    </div>
                    <div>
                      <h6>Miejsce urodzenia</h6>
                      <p>{womenData.born_place}</p>
                    </div>
                    <div>
                      <h6>Miejsce śmierci</h6>
                      <p>{womenData.death_place}</p>
                    </div>
                    <div>
                      <h6>Miejsca</h6>
                      <p>{womenData.places}</p>
                    </div>
                    <div>
                      {womenData.tags.map((tag: any) => (
                        <Button key={`tag-${tag.id}`} variant="outline-primary" style={{ margin: '5px' }}>
                          {tag.name}
                        </Button>
                      ))}
                    </div>
                  </Card.Text>
                </Card.Body>
              </Col>
            </Row>
            <Row>
              <div>
                <h2>Oś czasu</h2>
              </div>
              <div className="timeline">
                <Chrono
                  items={womenData.history_timeline}
                  mode="HORIZONTAL"
                  showSinglecenter
                  cardHeight={120}
                  theme={{
                    primary: 'black',
                    titleColor: 'black',
                    cardTitleColor: 'black',
                    secondary: 'white',
                  }}
                />
              </div>
            </Row>
            <Row>
              <div className="main-content">
                <div>
                  <h2>Życiorys</h2>
                </div>
                <div dangerouslySetInnerHTML={{ __html: womenData.main_content }} />
              </div>
            </Row>
            {womenData.picture_history_image && (
              <Row className="picture-history">
                <div>
                  <h2>Krótka herstoria jednego zdjęcia</h2>
                </div>
                <Col md={6}>
                  <Image src={womenData.picture_history_image} alt={womenData.fullname} fluid />
                  <div dangerouslySetInnerHTML={{ __html: womenData.picture_history_image_desc }} />
                </Col>
                <Col md={6}>
                  <div dangerouslySetInnerHTML={{ __html: womenData.picture_history_content }} />
                </Col>
              </Row>
            )}
            <Row className="quotes">
              <div>
                <h2>Cytaty</h2>
              </div>
              <div style={{ textAlign: 'center' }}>
                <div dangerouslySetInnerHTML={{ __html: womenData.quotes }} />
              </div>
            </Row>
            {womenData.commemoration && (
              <Row>
                <div>
                  <h2>Upamiętnienia</h2>
                </div>
                <div className="commemoration">
                  <div style={{ textAlign: 'left' }}>
                    <div dangerouslySetInnerHTML={{ __html: womenData.commemoration }} />
                  </div>
                </div>
              </Row>
            )}
            <Row className="biblography">
              <div>
                <h2>Bibliografia</h2>
              </div>
              <div style={{ textAlign: 'left' }}>
                <div dangerouslySetInnerHTML={{ __html: womenData.bibliography }} />
              </div>
            </Row>
            {womenData.media?.photos && (
              <Row className="gallery">
                <div>
                  <h2>Galeria</h2>
                </div>
                <div>
                  <ImageGallery items={galleryItems} />
                </div>
              </Row>
            )}
            {womenData.otherLadies && (
              <Row>
                <div>
                  <h2>Powiązane kobiety</h2>
                </div>
                <div className="other-ladies">
                  <div style={{ textAlign: 'left' }}>
                    <div dangerouslySetInnerHTML={{ __html: womenData.otherLadies }} />
                  </div>
                </div>
              </Row>
            )}
            {womenData.other && (
              <Row>
                <div>
                  <h2>Inne</h2>
                </div>
                <div className="other">
                  <div style={{ textAlign: 'left' }}>
                    <div dangerouslySetInnerHTML={{ __html: womenData.other }} />
                  </div>
                </div>
              </Row>
            )}

            {womenData.media?.links && (
              <Row>
                <div>
                  <h2>Linki</h2>
                </div>
                <div className="links">
                  <div style={{ textAlign: 'left' }}>
                    <ul>
                      {womenData.media.links.map((link: any, index: number) => (
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip id={`tooltip-${index}`}>{link.description}</Tooltip>}
                        >
                          <li>
                            <a href={link.url} target="_blank" rel="noopener noreferrer">
                              {link.name}
                            </a>
                          </li>
                        </OverlayTrigger>
                      ))}
                    </ul>
                  </div>
                </div>
              </Row>
            )}
          </Animate>
        )}
      </Container>
    </div>
  );
};

export default SingleWomen;
