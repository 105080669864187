interface IRoutePath {
  relativePath: string;
  absolutePath: string;
}

interface IRoutes {
  Home: IRoutePath;
  Login: IRoutePath;
  Register: IRoutePath;
  Lock: IRoutePath;
  Profile: IRoutePath;
  ResetPassword: IRoutePath;
  VerifyEmail: IRoutePath;
  NotFound: IRoutePath;
  Dashboard: IRoutePath;
  ForgotPassword: IRoutePath;
  UserList: IRoutePath;
  NewUser: IRoutePath;
  EditUser: IRoutePath;
  Womens: IRoutePath;
  NewWomen: IRoutePath;
  SingleWomen: IRoutePath;
  WomensInventory: IRoutePath;
  Faq: IRoutePath;
  About: IRoutePath;
}

const routes: IRoutes = {
  Home: { relativePath: '/', absolutePath: '/' },
  Womens: { relativePath: 'kobiety', absolutePath: '/kobiety' },
  NewWomen: { relativePath: 'dodaj-bohaterke', absolutePath: '/dodaj-bohaterke' },
  Faq: { relativePath: 'faq', absolutePath: '/faq' },
  About: { relativePath: 'o-bazie', absolutePath: '/o-bazie' },
  SingleWomen: { relativePath: 'kobiety/:id', absolutePath: '/kobiety/:id' },
  WomensInventory: { relativePath: 'kobiety/admin', absolutePath: '/kobiety/admin' },
  Login: { relativePath: 'login', absolutePath: '/login' },
  Register: { relativePath: 'register', absolutePath: '/register' },
  Lock: { relativePath: 'lock', absolutePath: '/lock' },
  ResetPassword: { relativePath: 'reset-password', absolutePath: '/reset-password' },
  VerifyEmail: { relativePath: 'verify-email', absolutePath: '/verify-email' },
  NotFound: { relativePath: 'not-found', absolutePath: '/not-found' },
  Dashboard: { relativePath: 'dashboard', absolutePath: '/dashboard' },
  Profile: { relativePath: 'profile', absolutePath: '/dashboard/profile' },
  ForgotPassword: { relativePath: 'forgot-password', absolutePath: '/forgot-password' },
  UserList: { relativePath: 'user-list', absolutePath: '/dashboard/user-list' },
  NewUser: { relativePath: 'new', absolutePath: '/dashboard/user-list/new' },
  EditUser: { relativePath: ':id', absolutePath: '/dashboard/user-list/:id' },
};

export default routes;
