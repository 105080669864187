import React, { useState, useEffect } from 'react';
import { Table, Spinner, Row, Container, Form } from 'react-bootstrap';
import CustomPagination from '../common/components/paginator/Paginator';

const WomenTable = () => {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState<number>(20);

  useEffect(() => {
    setData([
      {
        id: 1,
        name: 'Aleksandra Piłsudska',
        isActive: true,
        routeLink: 'aleksandra-pilsudska',
      },
      {
        id: 2,
        name: 'Izabela Czartoryska',
        isActive: true,
        routeLink: 'izabela-czartoryska',
      },
    ]);
    setLoading(false);
  }, []);
  // Get current data
  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = data.slice(indexOfFirstData, indexOfLastData);

  // Change page
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  return (
    <div className="d-flex justify-content-center w-50 m-auto">
      {loading ? (
        <Spinner animation="border" variant="primary" />
      ) : (
        <Container fluid>
          <Row>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Is active</th>
                  <th>Route Link</th>
                </tr>
              </thead>
              <tbody>
                {currentData.map((item, index) => (
                  <tr key={item.id}>
                    <td>{index + 1}</td>
                    <td>{item.name}</td>
                    <td>
                      <Form>
                        <Form.Check type="switch" id={`custom-switch-${item.id}`} label="" checked />
                      </Form>
                    </td>
                    <td>{item.routeLink}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Row>
          <Row>
            <CustomPagination
              dataPerPage={dataPerPage}
              totalData={data.length}
              paginate={paginate}
              currentPage={currentPage}
            />
          </Row>
        </Container>
      )}
    </div>
  );
};

export default WomenTable;
