/* eslint-disable import/order */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { Animate } from '../common/components';
import '../../styles/womens.scss';
import { Accordion, Card, Container, Row } from 'react-bootstrap';

const FaqHome = () => {
  const navigate = useNavigate();

  return (
    <div className="d-flex justify-content-center w-50 m-auto">
      <Container fluid>
        <Animate>
          <Row>
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Skąd pomysł na Muzeum historii Kobiet?</Accordion.Header>
                <Accordion.Body>
                  Pomysł na MHK wziął się z niezgody na wielką nieobecność kobiet. Każda z nas, zupełnie niezależnie,
                  znalazła swój sposób na radzenie sobie z tym brakiem. Niektóre robiły to działając w nauce, inne propagując
                  wiedzę herstoryczną, tworząc warsztaty dla dziewczyn czy pisząc książki o wspaniałych i wpływowych
                  kobietach w dziejach. Ale wszystkie czułyśmy, że potrzeba tak naprawdę czegoś więcej; czegoś wielkiego.
                  Idei. Gdy spotkałyśmy się po raz pierwszy w ósemkę, na zoomie, w czasie pandemii, debatując o wydaniu
                  wspólnej książki zawierającej biogramy ważnych kobiet w polskich dziejach, któraś z nas rzuciła – stwórzmy
                  Muzeum. I została prezeską Fundacji Muzeum Historii Kobiet, której głównym celem i IDEĄ jest właśnie to –
                  stworzenie Muzeum. Kobiet brakuje w podręcznikach. W nazwach ulic. Nie ma kobiecych postaci na pomnikach,
                  banknotach, nie ma zbyt wielu patronek szkół. Ich dzieł jest niewiele w muzeach. Gdy po raz pierwszy
                  zaczniesz to dostrzegać i nad tym się zastanawiać, trudno już o tym zapomnieć. To bolesne i bardzo
                  niesprawiedliwe. My chcemy oddać kobietom należne im miejsce Jesteśmy kobietami, a naszą tożsamość – tak
                  jak naszych mam, babek i prababek – tworzy wiele różnych ról. Herstoria to opowieść o nich wszystkich,
                  ukazująca ich złożoność i bogactwo – na przekór stereotypowym przekonaniom i oczekiwaniom. Pokazująca, że
                  dokonania kobiet oraz ich codzienne decyzje miały i mają znaczenie dla wszystkich.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Row>
        </Animate>
      </Container>
    </div>
  );
};

export default FaqHome;
