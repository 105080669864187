/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Navbar, Container, Nav, Form, Col, Row, InputGroup, FormControl, Image, Offcanvas } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import routes from '../routing/routes';
import HorizontalLine from '../../../assets/img/icons/horizontalLine.svg';
import LogoType from '../../../assets/img/icons/logo.png';
import '../../../styles/singleWomen.scss';

const PublicNav = () => {
  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const handleOffcanvasShow = () => setShowOffcanvas(true);
  const handleOffcanvasClose = () => setShowOffcanvas(false);

  return (
    <div>
      <Container fluid>
        <Row>
          {/* Conditionally render Navbar based on showOffcanvas */}
          {!showOffcanvas && (
            <Navbar expand="lg" bg="white" data-bs-theme="white" className="d-flex justify-content-center w-50 m-auto">
              <Container>
                <Navbar.Brand href="/">
                  <img className="header-logo" alt="Group" src={LogoType} />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={handleOffcanvasShow} />
                <Navbar.Collapse id="responsive-navbar-nav">
                  <Nav className="me-auto" />
                  <Nav className="navbar-custom">
                    <Nav.Link href="/o-bazie" style={{ fontSize: '12px', fontWeight: 'bold' }}>
                      O BAZIE
                    </Nav.Link>
                    <Nav.Link href="/faq" className="custom-nav-link" style={{ fontSize: '12px', fontWeight: 'bold' }}>
                      FAQ
                    </Nav.Link>
                    <Nav.Link
                      href="/dodaj-bohaterke"
                      className="custom-nav-link"
                      style={{ fontSize: '12px', fontWeight: 'bold' }}
                    >
                      DODAJ BOHATERKĘ
                    </Nav.Link>
                    <Nav.Link
                      href="https://historiakobiet.org/kontakt/"
                      target="_blank"
                      className="custom-nav-link"
                      style={{ fontSize: '12px', fontWeight: 'bold' }}
                    >
                      KONTAKT
                    </Nav.Link>
                  </Nav>
                </Navbar.Collapse>
              </Container>
            </Navbar>
          )}
        </Row>
        <Row className="d-flex justify-content-center w-50 m-auto">
          <Image src={HorizontalLine} />
        </Row>
      </Container>

      {/* Offcanvas component */}
      <Offcanvas show={showOffcanvas} onHide={handleOffcanvasClose} placement="start">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Menu</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav className="flex-column">
            <Nav.Link href="/o-bazie">O BAZIE</Nav.Link>
            <Nav.Link href="/faq">FAQ</Nav.Link>
            <Nav.Link href="/dodaj-bohaterke">DODAJ BOHATERKĘ</Nav.Link>
            <Nav.Link href="https://historiakobiet.org/kontakt/" target="_blank">
              KONTAKT
            </Nav.Link>
          </Nav>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default PublicNav;
