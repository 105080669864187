/* eslint-disable no-unused-vars */
import React from 'react';
import { Col, Container } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';
import Footer from '../../dashboard/components/footer/Footer';
import NotifyContainer from './Notify';
import PublicNav from './PublicNav';
import DynamicBreadcrumb from '../../dashboard/components/breadcrumb/Breadcrumb';

const Layout = () => {
  return (
    <Container fluid className="px-0 min-vh-100">
      <NotifyContainer />
      <PublicNav />
      <DynamicBreadcrumb />
      <Outlet />
      <Footer />
    </Container>
  );
};

export default Layout;
