/* eslint-disable react/no-unescaped-entities */
/* eslint-disable import/order */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { Animate } from '../common/components';
import '../../styles/womens.scss';
import { Card, Col, Container, Row, Image } from 'react-bootstrap';
import Avatar from '../../assets/img/avatars/avatar.png';
import About from '../../assets/img/illustrations/about.png';
import HorizontalLine from '../../assets/img/icons/horizontalLine.svg';
import SektorImage from '../../assets/img/company/sektor.png';
import FRSIImage from '../../assets/img/company/frsi.png';
import PafwImage from '../../assets/img/company/pafw.png';
import MKiDNImage from '../../assets/img/company/mkidn.jpg';
import MhpImage from '../../assets/img/company/mhp.jpg';
import PImage from '../../assets/img/company/pj.jpg';

const AboutHome = () => {
  const navigate = useNavigate();

  return (
    <Animate>
      <div className="d-flex justify-content-center w-50 m-auto">
        <Container fluid>
          <Row>
            {/* <Col>
              <Image src={About} fluid width="100%" />
            </Col> */}
            <Col>
              <p className="baza-historii-kobiet">
                Baza Historii Kobiet to pierwsze tak obszerne i kompleksowe repozytorium wiedzy i materiałów edukacyjnych na
                temat bohaterek historii Polski oraz świata. Znajdziesz tu syntetyczne biogramy, materiały fotograficzne,
                audio oraz wideo, a także liczne odnośniki do miejsc,
                <br />w których możesz pogłębić swoją wiedzę na ich temat.
                <br />
                <br />W zakładce POBIERZ MATERIAŁY znajdziesz scenariusze lekcji, sketchnotki oraz inne pomoce edukacyjne,
                które za darmo możesz wykorzystać na swoich zajęciach lub w swoich projektach.
                <br />
                <br />
                Historia kobiet jest w zbiorowej świadomości Polaków i Polek niemal nieobecna. Nie ma jej w programach
                szkolnych (tylko 4% nazwisk wymienionych w podręcznikach należy do kobiet), ani miejscach pamięci (tylko 2%
                nazw ulic i 10% patronów szkół), ani politykach historycznych (por. celebrowanie wyłącznie “ojców
                niepodległości”).
                <br />
                <br />
                Tymczasem sprawiedliwa, równościowa, uwzględniająca zasługi i wkład kobiet historia, stanowi fundament
                inkluzywnego społeczeństwa obywatelskiego, a dla młodzieży obojga płci jest źródłem różnorodnych wzorców
                postępowania i autorytetów. Jest też sprawdzonym środkiem zwalczania stereotypów odpowiedzialnych za
                dyskryminację ze względu na płeć. Skutecznie poszerza horyzont aspiracji dziewcząt, kobiet, chłopców i
                mężczyzn.
              </p>
            </Col>
          </Row>
          <Row>
            <img className="vector-3" alt="Vector" src={HorizontalLine} />
          </Row>

          {/* <Row>
            <h4>Baze tworzą:</h4>
          </Row>
          <Row className="justify-content-center">
            <Col xs="auto">
              <Card style={{ width: '10rem' }}>
                <Card.Img variant="top" src={Avatar} className="rounded" />
                <Card.Body>
                  <Card.Title>Anna Jankowiak</Card.Title>
                  <Card.Text>Anna Jankowiak.</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row> */}
          <Row>
            <h4>Wspierają nas:</h4>
            <Row>
              <Col xm="auto">
                <div style={{ textAlign: 'center' }} className="support-item">
                  <Image src={SektorImage} style={{ maxWidth: '50%', height: 'auto' }} fluid />
                </div>
              </Col>
              <Col xm="auto">
                <div style={{ textAlign: 'center' }} className="support-item">
                  <Image src={FRSIImage} style={{ maxWidth: '60%', height: 'auto' }} fluid />
                </div>
              </Col>
              <Col xm="auto">
                <div style={{ textAlign: 'center' }} className="support-item">
                  <Image src={PafwImage} style={{ maxWidth: '60%', height: 'auto' }} fluid />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xm="auto">
                <div style={{ textAlign: 'center' }} className="support-item">
                  <Image src={MKiDNImage} style={{ maxWidth: '100%', height: 'auto' }} fluid />
                </div>
              </Col>
              <Col xm="auto">
                <div style={{ textAlign: 'center' }} className="support-item">
                  <Image src={MhpImage} style={{ maxWidth: '100%', height: 'auto' }} fluid />
                </div>
              </Col>
              <Col xm="auto">
                <div style={{ textAlign: 'center' }} className="support-item">
                  <Image src={PImage} style={{ maxWidth: '80%', height: 'auto' }} fluid />
                </div>
              </Col>
            </Row>

            <p>
              Działanie współfinansowane ze środków Polsko-Amerykańskiej Fundacji Wolności w ramach Programu "Nowe
              Technologie Lokalnie 2023 - Sektor 3.0", którego realizatorem jest Fundacja Rozwoju Społeczeństwa
              Informacyjnego.
            </p>
          </Row>
        </Container>
      </div>
    </Animate>
  );
};

export default AboutHome;
