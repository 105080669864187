/* eslint-disable no-unused-expressions */
/* eslint-disable react/button-has-type */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable import/order */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Animate } from '../common/components';
import '../../styles/womens.scss';
import { useGetAllFiltersQuery, useGetWomensQuery, useLazyGetWomensQuery } from './womens.api';
import { Breadcrumb, Card, Col, Container, Form, FormControl, Row, Spinner, Image, CarouselItem } from 'react-bootstrap';
import Select from 'react-select';
import debounce from 'lodash/debounce';
import LogoHeader from '../../assets/img/illustrations/homeLogo.png';
import LogoResult from '../../assets/img/illustrations/resultsLogo.png';
import LeftArray from '../../assets/img/icons/leftArray.png';
import RightArray from '../../assets/img/icons/rightArray.png';
import HorizontalLine from '../../assets/img/icons/horizontalLine.svg';
import { useSpringCarousel } from 'react-spring-carousel';

interface Option {
  value: string;
  label: string;
}

// Define the type for a group of options.
interface OptionGroup {
  label: string;
  options: Option[];
}

interface FilterParams {
  keyword: string;
  fullname: number[];
  times: number[];
  tags: number[];
}

const WomensHome = () => {
  const mockItems = [
    {
      id: '1',
      title: 'PRADZIEJE',
      desc: 'DO 4000/3500 LAT P.N.E.',
    },
    {
      id: '2',
      title: 'ŚREDNIOWIECZE',
      desc: '476-1492',
    },
    {
      id: '3',
      title: 'NOWOŻYTNOŚĆ',
      desc: '1492 - 1789',
    },
    {
      id: '4',
      title: 'XIX WIEK',
      desc: '1789 - 1914',
    },
    {
      id: '5',
      title: 'WSPÓŁCZESNOŚĆ',
      desc: '1914 - DZIŚ',
    },
  ];
  const filters = useGetAllFiltersQuery({});
  const [shouldNavigate, setShouldNavigate] = useState(false);
  const [womenData, setWomenData] = useState<any>([]);
  const [isFiltertTaken, setIsFiltersTaken] = useState<boolean>(false);

  const [selectedFullnames, setSelectedFullnames] = useState<any>([]);
  const [selectedTags, setSelectedTags] = useState<any>([]);
  const [selectedTimes, setSelectedTimes] = useState<any>([]);

  const [tagOptions, setTagOptions] = useState<OptionGroup[]>([]);
  const [timeOptions, setTimeOptions] = useState<OptionGroup[]>([]);
  const [timeCarousel, setTimeCarousel] = useState<any>(null);
  const [fullnameOptions, setFullnameOptions] = useState<OptionGroup[]>([]);
  const [keyword, setKeywordValue] = useState<any>('');
  const [isInit, setIsInit] = useState<boolean>(true);

  const [currentSlide, setCurrentSlide] = useState(mockItems[0].id);

  function handleClick(event: any) {
    setSelectedFullnames([]);
    setSelectedTags([]);
    setSelectedTimes([]);
    setKeywordValue('');
    setSelectedTimes([timeOptions[0].options[Number(event) - 1]]);
  }

  const { carouselFragment, slideToPrevItem, slideToNextItem, useListenToCustomEvent } = useSpringCarousel({
    itemsPerSlide: 5,
    withLoop: true,
    initialStartingPosition: 'center',
    gutter: 44,
    items: mockItems.map((item: any, index: number) => {
      return {
        ...item,
        renderItem: (
          <Col className="mx-auto">
            <div className={`${currentSlide === item.id ? 'image-centered' : 'image-next'}`}>
              <Image
                src={require(`../../assets/img/illustrations/time${item.id}.png`)}
                roundedCircle
                width={`${currentSlide === item.id ? '160px' : '130px'}`}
                onClick={(event) => handleClick(item.id)}
              />
            </div>
            <h6>{item.title}</h6>
            <p>{item.desc}</p>
          </Col>
        ),
      };
    }),
  });

  useEffect(() => {
    if (currentSlide && filters.data) {
      handleClick(currentSlide);
    }
  }, [currentSlide]);

  useListenToCustomEvent((event) => {
    if (event.eventName === 'onSlideStartChange') {
      setCurrentSlide(event?.nextItem?.id);
    }
  });

  const [dataFilter, setDataFilter] = useState<FilterParams>({
    keyword: '',
    fullname: [],
    times: [],
    tags: [],
  });
  // const { data, isLoading, isFetching, refetch } = useGetWomensQuery(dataFilter, { skip: true });
  const [getWomensQuery, { data, isLoading, isFetching }] = useLazyGetWomensQuery();

  const navigate = useNavigate();

  const debouncedHandleInputChange = debounce(() => {
    const updatedDataFilter: FilterParams = {
      keyword,
      fullname: selectedFullnames.map((fullname: any) => fullname.value).join(','),
      times: selectedTimes.map((time: any) => time.value),
      tags: selectedTags.map((tag: any) => tag.value),
    };
    // eslint-disable-next-line no-shadow
    setDataFilter(updatedDataFilter);
  }, 2000);

  useEffect(() => {
    if (dataFilter.keyword !== '' || dataFilter.fullname.length || dataFilter.times.length || dataFilter.tags.length) {
      getWomensQuery(dataFilter);
    } else {
      setWomenData([]);
    }
  }, [dataFilter]);

  useEffect(() => {
    if (womenData !== data) {
      setWomenData(data);
    }
  }, [data]);

  useEffect(() => {
    if (filters.data) {
      const newTimeOptions: OptionGroup[] = [
        {
          label: 'Epoka',
          options: filters.data.times.map((time: any, index: number) => ({
            value: time.id,
            label: time.name,
            imagePath: require(`../../assets/img/illustrations/time${index + 1}.png`),
          })),
        },
      ];

      setTimeOptions(newTimeOptions);

      const newTagOptions: OptionGroup[] = [
        {
          label: 'Profesja',
          options: filters.data.tags.map((tag: any) => ({
            value: tag.id,
            label: tag.name,
          })),
        },
      ];

      setTagOptions(newTagOptions);

      const newFullnameOptions: OptionGroup[] = [
        {
          label: 'Imię i nazwisko',
          options: filters.data.fullnames.map((fullname: any) => ({
            value: fullname.id,
            label: fullname.fullname,
          })),
        },
      ];

      setFullnameOptions(newFullnameOptions);

      setIsInit(false);

      setIsFiltersTaken(true);
    }
  }, [filters]);

  useEffect(() => {
    if (!isInit) {
      debouncedHandleInputChange();
    }
  }, [selectedFullnames, selectedTags, selectedTimes, keyword]);

  useEffect(() => {
    if (timeOptions[0]?.options) {
      setSelectedTimes([timeOptions[0].options[0]]);
    }
  }, [timeOptions]);

  const handleChange = (selectedOptions: any, setStateFunction: any) => {
    setStateFunction(selectedOptions);
  };

  const handleCarouselChange = (index: string) => {
    // This function will be called whenever the carousel's index changes
    console.log(index);
  };

  return (
    <div className="d-flex justify-content-center w-50 m-auto">
      <Container fluid>
        <Animate>
          <Row>
            <Image src={LogoHeader} fluid />
          </Row>
          <Row>
            <div className="horizonalLine">
              <Image src={HorizontalLine} />
            </div>
          </Row>
          <Row>
            <div className="carousel-time">
              <div className="carousel-items">{carouselFragment}</div>
            </div>
            <div className="btnCarousele">
              <button onClick={slideToPrevItem} className="btnCarousele">
                <Image src={LeftArray} width="10px" />
              </button>
              <button onClick={slideToNextItem} className="btnCarousele">
                <Image src={RightArray} width="10px" />
              </button>
            </div>
          </Row>
          <Row>
            <div className="horizonalLine">
              <Image src={HorizontalLine} />
            </div>
          </Row>
          <Row>
            <h2>Poznaj kobiety różnych epok:</h2>
            <Row>
              {isFiltertTaken ? (
                <div className="filter-container">
                  {/* Fullname Dropdown */}
                  <div className="filter-item">
                    <Select
                      isMulti
                      options={fullnameOptions}
                      value={selectedFullnames}
                      onChange={(selectedOptions) => {
                        handleChange(selectedOptions, setSelectedFullnames);
                        debouncedHandleInputChange();
                      }}
                      placeholder="Imię i nazwisko"
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderColor: state.isFocused ? 'grey' : 'black',
                          borderRadius: '20px',
                        }),
                      }}
                    />
                  </div>

                  {/* Tag Dropdown */}
                  <div className="filter-item">
                    <Select
                      isMulti
                      options={tagOptions}
                      value={selectedTags}
                      onChange={(selectedOptions) => {
                        handleChange(selectedOptions, setSelectedTags);
                        debouncedHandleInputChange();
                      }}
                      placeholder="Profesja"
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderColor: state.isFocused ? 'grey' : 'black',
                          borderRadius: '20px',
                        }),
                      }}
                    />
                  </div>

                  {/* Time Dropdown */}
                  <div className="filter-item">
                    <Select
                      isMulti
                      options={timeOptions}
                      value={selectedTimes}
                      onChange={(selectedOptions) => {
                        handleChange(selectedOptions, setSelectedTimes);
                        debouncedHandleInputChange();
                      }}
                      placeholder="Epoka"
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderColor: state.isFocused ? 'grey' : 'black',
                          borderRadius: '20px',
                        }),
                      }}
                    />
                  </div>

                  {/* Keyword Search */}
                  <div className="filter-item">
                    <FormControl
                      type="text"
                      size="sm"
                      placeholder="Słowa klucz"
                      value={keyword.value}
                      onChange={(e) => {
                        setKeywordValue(e.target.value);
                        debouncedHandleInputChange();
                      }}
                      className="custom-line-height"
                    />
                  </div>
                </div>
              ) : (
                <div style={{ textAlign: 'center' }}>
                  <Spinner animation="border" />
                </div>
              )}
            </Row>

            <Row>
              {!isFetching ? (
                womenData &&
                womenData.map((women: any, index: number) => (
                  <Col sm={4} key={index}>
                    <div className="women-div-card d-flex justify-content-center align-items-center">
                      <a href={`/kobiety/${women.route_id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                        {/* Wrap Card in an anchor tag */}
                        <Card>
                          <div
                            className="d-flex justify-content-center align-items-center"
                            style={{ width: '100%', height: '100%' }}
                          >
                            <Card.Img
                              variant="top"
                              src={women.main_photo}
                              className="rounded img-fluid"
                              style={{ width: '280px', height: '372px', objectFit: 'cover' }}
                            />
                          </div>
                          <Card.Body className="card-body-women">
                            <Card.Title>{women.fullname}</Card.Title>
                            <Card.Text>{women.short_description}</Card.Text>
                          </Card.Body>
                        </Card>
                      </a>
                    </div>
                  </Col>
                ))
              ) : (
                <div style={{ textAlign: 'center' }}>
                  <Spinner animation="border" />
                </div>
              )}
            </Row>
            {/* <Row>
              <Col className="d-flex justify-content-center align-items-center">
                <Image src={LogoResult} fluid />
              </Col>
            </Row> */}
          </Row>
        </Animate>
      </Container>
    </div>
  );
};

export default WomensHome;
