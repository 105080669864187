import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { IQueryResults } from '../common/definitions';
import { IGetSingleWomenRequest } from './womens.types';

const apiURL = process.env.REACT_APP_APIKEY;
const womenAPI = createApi({
  reducerPath: 'womenAPI',
  baseQuery: fetchBaseQuery({ baseUrl: apiURL }),
  endpoints: (builder) => ({
    getWomens: builder.query<IQueryResults<any>, any>({
      query: (params) => ({
        url: 'womens',
        params,
      }),
    }),
    getSingleWomen: builder.query<IGetSingleWomenRequest, IGetSingleWomenRequest>({
      query: ({ id }) => ({
        url: `womens/${id}`,
        method: 'GET',
      }),
    }),
    getAllFilters: builder.query<any, any>({
      query: () => ({
        url: `womens/filters`,
        method: 'GET',
      }),
    }),
  }),
});
export const { useGetWomensQuery, useLazyGetWomensQuery, useGetSingleWomenQuery, useGetAllFiltersQuery } = womenAPI;
export default womenAPI;
